<script>

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import circuitMixin from "@mixins/circuitMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import circuitService from "@services/circuitService";
import bilanCircuitService from "@services/bilanCircuitService";

export default {
	components: { AkFormView, AkDropdown },
	mixins: [randomRef, utilsMixin, circuitMixin, roleMixin],
	metaInfo() {
		return {
			title: "bilan_circuit.synthese",
		}
	},
	data() {
		return {
			year: 0,
			yearList: [],
			circuitList: [],
			list: [],
			localFields: ['nb_conduite', 'lineaire_total', 'lineaire_salage', 'surface_salage', 'duree_estime_traitement', 'duree_estime_parcours', 'tonnage_estime_sel', 'capacite_saleuse', 'nb_inter_precur', 'nb_inter_cur', 'nb_inter_pts_sensible', 'ton_dry_salt_used', 'litre_brine_used'],
			dbFields: ['nbConduite', 'lineaireTotal', 'lineaireSalage', 'surfaceSalage', 'dureeEstimeTraitement', 'dureeEstimeParcours', 'tonnageEstimeSel', 'capaciteSaleuse', 'nbInterPrecur', 'nbInterCur', 'nbInterPtsSensible', 'tonDrySaltUsed', 'litreBrineUsed'],
		}
	},
	mounted() {
		this.loading = true;
		this.getRef().showTotalLoader();

		let p1 = circuitService.findAllCurrent();
		p1.then(data => this.circuitList = data);

		let p2 = bilanCircuitService.findSyntheseByCurrentYear();
		p2.then(data => this.list = data);

		let p3 = bilanCircuitService.findAllYears();
		p3.then(data => {
			this.yearList.push({ value: 0, label: this.$t('current_year') });
			data.forEach(year => this.yearList.push({ value: year, label: year + ' - ' + (year + 1) }))
		});

		Promise.all([p1, p2, p3]).then(() => this.afterPromise());
	},
	methods: {
		initData() {
			this.initCircuitListForTable(this.circuitList);
			this.list.forEach(el => { this.map[el.circuitId] = el; });
		},
		afterPromise() {
			this.loading = false;
			this.getRef().hideLoader();
			this.initData();
		},
		countForField(circuit, field) {
			let data = this.map[circuit.id];
			if (!data) return '';
			let count = data[field];
			if (count) return count;
			return count === 0 ? 0 : '';
		},
		reload(year) {
			this.loading = true;
			this.getRef().showTotalLoader();
			let p1;
			let p2;
			if (year !== 0) {
				p1 = circuitService.findAllByYear(year);
				p1.then(data => this.circuitList = data);

				p2 = bilanCircuitService.findSyntheseByYear(year);
				p2.then(data => this.list = data);
			} else {
				p1 = circuitService.findAllCurrent();
				p1.then(data => this.circuitList = data);

				p2 = bilanCircuitService.findSyntheseByCurrentYear();
				p2.then(data => this.list = data);
			}

			Promise.all([p1, p2]).then(() => this.afterPromise());
		},
		download() {
			let p = bilanCircuitService.download(this.year);
			let promises = [p];
			p.catch(e=>{
				this.getRef().hideLoader();
				this.getRef().error(this.$t("error_download_synthese"));
			});
			Promise.all(promises).then(() => console.log('downloaded'));
		}
	},
	computed: {
		map() {
			let map = {};
			this.list.forEach(el => { map[el.circuitId] = el; });
			return map;
		},
		displayArchiveData(){
			return this.year !== 0;
		}
	},
	watch: {
		year(newType) {
			this.reload(newType);
		},
	},
}
</script>

<template v-if=!roleLoading>
	<AkFormView :ref="ref" :title="$t('bilan_circuit.synthese')" :displayGoBack=false>
		<template v-slot:action>
			<button @click="download()" style="margin-right: 10px;" class="btn btn-inverse-primary">
				<i class="fe fe-download pr-1" />
				{{ $t('download_excel') }}
			</button>
			<RouterLink v-if=canEditCircuit :to="{ name: 'bilanCircuitList' }" class="btn btn-inverse-primary">
				<i class="fe fe-settings pr-1" />
				{{ $t('manage') }}
			</RouterLink>
		</template>
		<template v-slot:form>
			<div class="row">
				<AkDropdown :label="$t('year')" v-if=canViewCircuitArchived v-model="year" :options=this.yearList
					class-name="col-md-2" />
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card card-statistics">
						<div class="card-body">
							<div class="table-responsive forecast-table">
								<table style="display: block; overflow-x: auto;">
									<thead>
										<tr>
											<th scope="col">{{ $t('ager_label') }}</th>
											<th scope="col">{{ $t('uer_label') }}</th>
											<th scope="col">{{ $t('cei_label') }}</th>
											<th scope="col">{{ $t('bilan_circuit.circuit') }}</th>
											<th scope="col">{{ $t('bilan_circuit.sections') }}</th>
											<th scope="col">{{ $t('bilan_circuit.axes') }}</th>
											<th scope="col" v-for="field in localFields" :key="field">{{
											$t('bilan_circuit.' + field) }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="circuit in circuitList" :key="circuit.id">
											<td v-if=circuit.agerSize :rowspan=circuit.agerSize>{{ circuit.agerLabel }}
											</td>
											<td v-if=circuit.uerSize :rowspan=circuit.uerSize>{{ circuit.uerLabel }}
											</td>
											<td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ circuit.ceiLabel }}
											</td>
											<td>{{ circuit.number }}</td>
											<td>{{ circuit.section }}</td>
											<td>{{ circuit.axis }}</td>
											<td v-for="field in dbFields" :key="field">{{
												countForField(circuit, field)
											}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</AkFormView>
</template>